const requireContext = require.context('@/utils/model_view', false, /\.json$/);

/**
 * 根据文件名加载 JSON 文件
 * @param {string} fileName - 文件名
 * @returns {Object} - JSON文件
 */
export function loadJson(fileName) {
    if (requireContext.keys().includes(`./${fileName}.json`)) {
        return requireContext(`./${fileName}.json`);
    } else {
        console.warn(`File ${fileName}.json not found`);
        return null;
    }
}
